<template>
  <SettingsPage
    v-if="typeof members !== 'undefined' && canEdit"
    type="business"
  >
    <label class="label">Team</label>

    <nav class="level">
      <div class="level-left">
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button is-primary is-small" @click="addUser()" data-cy="create">
            <span class="icon">
              <i class="fas fa-plus-circle"></i>
            </span>
            <span>Add Team Member</span>
          </button>
        </div>
      </div>
    </nav>
    <b-table
      :data="members"
      :narrowed="false"
    >
      <b-table-column field="name" label="Name" sortable v-slot="props">
          {{props.row.name}}
      </b-table-column>
      <b-table-column field="email" label="Email" sortable  v-slot="props">
          {{props.row.email}}
      </b-table-column>
      <b-table-column field="role" label="Role" sortable  v-slot="props">
          {{props.row.role}}
      </b-table-column>
      <b-table-column  v-slot="props">
          <a
            v-if="props.row.slug != currentUser.slug"
            @click="editUser(props.row)"
          >
            edit
          </a>
          <p v-else>
            You
          </p>
      </b-table-column>
    </b-table>
    <div
      v-if="userFormOpen"
      class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="newUser" class="modal-card-title">
            Add a team member
          </p>
          <p v-else class="modal-card-title">
            {{ user.name }} ({{ user.email}})
          </p>
          <button @click="userFormOpen = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <UserForm
            :orgSlug="currentBusiness.slug"
            :isNew="newUser"
            :user="user"
            @close-form="closeForm"
          />
        </section>
      </div>
    </div>
  </SettingsPage>
</template>

<script>
import SettingsPage from "@/components/SettingsPage.vue";
import UserForm from '@/components/UserForm.vue'

export default {
  components: {
    SettingsPage,
    UserForm
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    canEdit: function() {
      return this.$store.getters.canEdit;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness
    },
    members: function() {
      if (typeof this.currentBusiness !== 'undefined' && typeof this.currentBusiness.users !== 'undefined') {
        return this.currentBusiness.users
      }
      return []
    },
  },
  data() {
    return {
      newUser: false,
      userFormOpen: false,
      user: {
        name: '',
        email: '',
        role: 'Approver'
      }
    }
  },
  methods: {
    addUser () {
      this.newUser = true
      this.userFormOpen = true
    },
    editUser (user) {
      console.log(user)
      this.user = user
      this.newUser = false
      this.userFormOpen = true
    },
    closeForm () {
      this.userFormOpen = false
      this.refreshCurrentBusiness()
    },
    refreshCurrentBusiness () {
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug
      }
      this.$store.dispatch("setCurrentBusiness", params)
    }
  }
}
</script>
