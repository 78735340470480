<template>
  <div>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <div v-if="isNew && !emailSelected">
      <label class="label">Add by email</label>
      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="enter an email address"
            v-model.trim="$v.emailSearch.$model"
            :validation="$v.emailSearch"
            @input="detectEmailSearch()"
          >
        </div>
        <div class="control">
          <button :class="emailSearchBtn" :disabled="emailSearchDisabled" @click="searchEmail()">Find</button>
        </div>
      </div>
    </div>
    <form
      v-else
      @submit.prevent="onSubmit"
    >
      <div>
        <div v-if="isNew">
        <BaseInput
          label="Email"
          type="text"
          :disabled="true"
          v-model.trim="updateUser.email"
          @input="detectChange()"
        />
        </div>

        <div class="field">
          <label class="label">Role</label>
          <div class="control">
            <div class="select is-size-7">
              <select v-model="userRole" @change="detectChange()" data-cy="userRoleEdit">
                <option v-for="opt in ['Approver', 'Admin', 'Owner']" :key="opt" :value="opt">{{ opt }}</option>
              </select>
            </div>
          </div>
        </div>
        <BaseSubmit
          :label="submitLabel()"
          :disabled="disabled"
          :submitting="submitting"
          :cancelShow="false"
        />
        <a @click="cancelForm()">cancel</a>
        <div v-if="!isNew" class="mt-4">
          <a @click="removeMember()" class="has-text-danger">Remove from team</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {AuthError, NetworkError} from '@/errors'
import Notification from '@/components/Notification.vue'
import BaseInput from '@/components/form/BaseInput.vue'
import BaseSubmit from '@/components/form/BaseSubmit.vue'
import { email, required } from 'vuelidate/lib/validators'

export default {
  props: {
    orgSlug: {
      type: String,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    Notification,
    BaseInput,
    BaseSubmit
  },
  data () {
    return {
      error: null,
      submitting: false,
      disabled: true,
      emailSearchBtn: 'button is-primary',
      emailSearchDisabled: true,
      emailSelected: false,
      emailSearch: '',
      userRole: this.user.role,
      updateUser: this.user
    }
  },
  validations: {
    emailSearch: { required, email },
  },
  methods: {
    detectChange () {
      this.disabled = false
    },
    submitLabel () {
      if (this.isNew) {
        return 'Add'
      } else {
        return 'Save'
      }
    },
    detectEmailSearch () {
      if (this.$v.emailSearch.$error) {
        this.emailSearchDisabled = true
      } else {
        this.emailSearchDisabled = false
      }
    },
    searchEmail () {
      this.error = null
      this.$store.dispatch("findUserByEmail", this.emailSearch)
        .then(resp => {
          if (typeof resp.slug !== 'undefined') {
            this.updateUser.email = this.emailSearch
            this.updateUser.slug = resp.slug
            this.updateUser.role = 'Approver'
            this.disabled = false
            this.emailSelected = true
          }
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
          this.disabled = true
        })
        .finally(() => {
          this.submitting = false
        })
    },
    onSubmit () {
      this.submitting = true
      if (this.isNew) {
        this.addMember()
      } else {
        this.updateMember()
      }
    },
    refreshAndClose () {
      this.disabled = true
      this.submitting = false
      this.$emit('close-form')
    },
    cancelForm () {
      this.$emit('close-form')
    },
    addMember() {
      const params = {
        org_slug: this.orgSlug,
        user_slug: this.updateUser.slug,
        role: this.userRole
      }
      this.$store.dispatch("addTeamMember", params)
        .then(() => {
          this.refreshAndClose()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
            this.submitting = false
          }
        })
    },
    updateMember () {
      const params = {
        id: this.updateUser.OrgUserid,
        role: this.userRole
      }
      this.$store.dispatch("updateTeamMemberRole", params)
        .then(() => {
          this.refreshAndClose()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
            this.submitting = false
          }
        })
    },
    removeMember () {
      this.$store.dispatch("removeTeamMember", this.updateUser.OrgUserid)
        .then(() => {
          this.refreshAndClose()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = error
          }
        })
    }
  }

}
</script>
